import React, { useMemo, useState, useEffect } from "react";
import ccatData from './catMetadata.json';
import Table from "./Table";
import "./App.css";
import firebase from "firebase/app";
import "firebase/auth";


var firebaseConfig = {
  apiKey: "AIzaSyAgIkwsNQIPSavfORyTM0jg_rqkidiTZ2A",
  authDomain: "ccat-list.firebaseapp.com",
  databaseURL: "https://ccat-list.firebaseio.com",
  projectId: "project-437968813594",
};

if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
}

const Attributes = ({ values }) => {
  return (
    <div>
      {values.map((genre, idx) => {
        return (
          <span key={idx} className="badge">
            {genre}
          </span>
        );
      })}
    </div>
  );
};

const Cat = ({ values }) => {
  return (
    <div style={{textAlign: "center"}}>
      <img src={"/assets/cats/"+values+".png"} alt="cat" style={{width: "100px"}}></img>
    </div>
  );
};


const Login = (props) => {

  const [passSubmitVal, handlePassSubmit] = useState("");
  const [showLoading, setShowLoading] = useState([]);

  function signIn(passVal) {
    setShowLoading(true);
    firebase.auth().signInWithEmailAndPassword('catlistview@thetwentysix.io', passVal)
    .then((userCredential) => {
      // Signed in
      console.log("LoggedIn");
      setShowLoading(false);
      props.updateLogin(true);

      // ...
    })
    .catch((error) => {
      var errorCode = error.code;
      var errorMessage = error.message;
      console.log("Login Fail");
      console.log(errorCode);
      console.log(errorMessage);
      setShowLoading(false);
      props.updateLogin(false);
    });
  }

  function updatePass(e) {
    handlePassSubmit(e.target.value);
  }

  function submitPass(e) {
    if (e) {
      e.preventDefault();
      console.log(passSubmitVal);
      signIn(passSubmitVal);
    }
    
  }

  return (
    <div style={{textAlign: "center"}}>
      <form onSubmit={submitPass}>
        <label>
          Password:&nbsp;
          <input type="password" value={passSubmitVal} onChange={updatePass} />
        </label>
        &nbsp;
        <input type="submit" value="Submit" />
      </form>
      { showLoading === true && <div style={{fontStyle: "italic"}}>Loading....</div> }
    </div>
  );
};

function App(props) {
  const columns = useMemo(
    () => [
      {
        Header: "Cats",
        columns: [
          {
            Header: "Image",
            accessor: "id",
            Cell: ({ cell: { value } }) => <Cat values={value} />
          },
          {
            Header: "Id",
            accessor: "name"
          },
          {
            Header: "Type",
            accessor: "type"
          },
          {
            Header: "Release",
            accessor: "release"
          },
          {
            Header: "Attribute(s)",
            accessor: "attributes",
            Cell: ({ cell: { value } }) => <Attributes values={value} />
          },
          {
            Header: "Attribute Count",
            accessor: "attributeCount"
          },
          {
            Header: "Colour",
            accessor: "colour"
          }
        ]
      }
    ],
    []
  );

  const [data, setData] = useState([]);
  const [loggedIn, updateLoggedIn] = useState([]);

  useEffect(() => {
    (async () => {
      setData(ccatData);
    })();
  }, []);

  if (loggedIn === true) {
    return (
      <div className="App">
        <Table columns={columns} data={data} />
      </div>
    );  
  } else {
    return (
      <div className="App">
        <Login updateLogin={updateLoggedIn} />
      </div>
    );    
  }

}

export default App;
