import React, { useState } from "react";
import { useTable, useFilters, useSortBy } from "react-table";

export default function Table({ columns, data }) {
  const [filterInput, setFilterInput] = useState("");
  const [colourFilterInput, setColourFilterInput] = useState("");
  // Use the state and functions returned from useTable to build your UI
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    setFilter
  } = useTable(
    {
      columns,
      data
    },
    useFilters,
    useSortBy
  );

  const handleFilterChange = e => {
    const value = e.target.value || undefined;
    setFilter("attributes", value);
    setFilterInput(value);
    // console.log(rows);
  };

  const handleColourFilterChange = e => {
    const value = e.target.value || undefined;
    setFilter("colour", value);
    setColourFilterInput(value);
  }

  const handleTypeFilterChange = e => {
    const value = e.target.value || undefined;
    setFilter("type", value);
    // setTypeFilterInput(value);
  }

  const handleEditionFilterChange = e => {
    const value = e.target.value || undefined;
    setFilter("release", value);
    // setTypeFilterInput(value);
  }

  const handleCountFilterChange = e => {
    const value = e.target.value || undefined;
    setFilter("attributeCount", value);
    // setTypeFilterInput(value);
  }

  // let totalRecords = sortedData.length;

  // Render the UI for your table
  return (
    <>
      <div>
        <span>Attribute &nbsp;</span>
        <input
          value={filterInput}
          onChange={handleFilterChange}
          placeholder={"Search Attributes"}
        />
        <span>&nbsp;&nbsp;Colour &nbsp;</span>
        <input
          value={colourFilterInput}
          onChange={handleColourFilterChange}
          placeholder={"Search Colour"}
        />
      </div>

      <div>
        <span>Type &nbsp;</span>
        <select name="type" id="type" onChange={handleTypeFilterChange}>
          <option value="">--Select--</option>
          <option value="Punk Kitten">Punk Kitten</option>
          <option value="Scruffy Cat">Scruffy Cat</option>
          <option value="Nacho Cat">Nacho Cat</option>
          <option value="Fat Cat">Fat Cat</option>
          <option value="Yoko Cat">Yoko Cat</option>
          <option value="Bokky Cat">Bokky Cat</option>
          <option value="Lucky Cat">Lucky Cat</option>
          <option value="Fried Chicken Cat">Fried Chicken Cat</option>
        </select>
        <span>&nbsp;&nbsp;Release &nbsp;</span>
        <select name="type" id="type" onChange={handleEditionFilterChange}>
          <option value="">--Select--</option>
          <option value="#1">#1</option>
          <option value="#2">#2</option>
          <option value="#3">#3</option>
        </select>
        <span>&nbsp;&nbsp;Attribute Count &nbsp;</span>
        <select name="type" id="type" onChange={handleCountFilterChange}>
          <option value="">--Select--</option>
          <option value="0">0</option>
          <option value="1">1</option>
          <option value="2">2</option>
          <option value="3">3</option>
        </select>
      </div>

      <div style={{ textAlign: "right"}}><h3>Cat Count: {rows.length}</h3></div>
      <table {...getTableProps()}>
        <thead>
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <th
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  className={
                    column.isSorted
                      ? column.isSortedDesc
                        ? "sort-desc"
                        : "sort-asc"
                      : ""
                  }
                >
                  {column.render("Header")}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row, i) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map(cell => {
                  return (
                    <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </>
  );
}
